<template>
  <div>
    <div class="cr-title pb-2">
      {{ item.title }}
    </div>
    <div class="px-4" style="min-height: 40px;">
      <component
        class="cr-bg"
        noArrow
        fitContent
        :task="newTask"
        :is="$props.components[item.value]"
        v-on="listeners"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-title {
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
}

.cr-bg::v-deep {
  background-color: #fff !important;

  &.cr-flow-select {
    .v-input__control:hover::before {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      content: "";
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.08);
    }
  }
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import Group from "@/flow/views/components/commons/task/group";
import Assignee from "@/flow/views/components/commons/task/assignee";
import StartDate from "@/flow/views/components/commons/task/startDate";
import DueDate from "@/flow/views/components/commons/task/dueDate";
import Progress from "@/flow/views/components/commons/task/progress";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    components: {
      type: Object,
      default: () => ({ Group, Assignee, StartDate, DueDate, Progress })
    }
  },
  computed: {
    ...mapState("flowTasks", ["newTask"]),
    listeners() {
      return {
        "change:group": this.changeGroup,
        "click:member": this.clickMember,
        "change:startDate": this.changeStartDate,
        "delete:startDate": this.deleteStartDate,
        "change:dueDate": this.changeDueDate,
        "delete:dueDate": this.deleteDueDate,
        "change:progress": this.changeProgress
      };
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_NEW_TASK"]),
    changeGroup(groupId) {
      if (this.item.value !== "Group") return;

      this.SET_NEW_TASK({ groupId });
    },
    clickMember(member) {
      if (this.item.value !== "Assignee") return;

      const { userId } = member;
      const { assignees: _assignees } = this.newTask;
      let assignees = [..._assignees, member];
      if (_assignees.find(a => a.userId === userId)) {
        assignees = _assignees.filter(m => m.userId !== userId);
      }

      this.SET_NEW_TASK({ assignees });
    },
    changeStartDate(startDateTimeMillis) {
      if (this.item.value !== "StartDate") return;

      this.SET_NEW_TASK({ startDateTimeMillis });
    },
    deleteStartDate() {
      if (this.item.value !== "StartDate") return;

      this.SET_NEW_TASK({ startDateTimeMillis: 0 });
    },
    changeDueDate(dueDateTimeMillis) {
      if (this.item.value !== "DueDate") return;

      this.SET_NEW_TASK({ dueDateTimeMillis });
    },
    deleteDueDate() {
      if (this.item.value !== "DueDate") return;

      this.SET_NEW_TASK({ dueDateTimeMillis: 0 });
    },
    changeProgress(progress) {
      if (this.item.value !== "Progress") return;

      this.SET_NEW_TASK({ progress });
    }
  }
};
</script>
