<template>
  <div class="cr-progress-wrapper" :class="{ 'cr-disabled': disabled }">
    <v-slider
      dense
      thumb-label
      hide-details
      min="0"
      max="100"
      color="primary darken-2"
      v-model="progress"
      @click.stop
      @mouseup="mouseup"
    />
    <div class="cr-label">{{ progress }}%</div>
  </div>
</template>

<style lang="scss" scoped>
.cr-progress-wrapper::v-deep {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  background-color: #fff;
  border-radius: 8px;
  border: thin solid rgba(0, 0, 0, 0.12);

  .cr-label {
    text-align: end;
    min-width: 40px;
  }

  .v-slider__thumb-container--active,
  .v-slider__thumb-label {
    z-index: 3;
  }

  &.cr-disabled {
    .v-slider__thumb-container {
      .v-slider__thumb::before {
        display: none !important;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
}
</style>

<script>
export default {
  emits: ["change:progress"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.progress = this.task?.progress || 0;
  },
  data() {
    return { progress: 0 };
  },
  watch: {
    task({ progress }) {
      this.progress = progress;
    }
  },
  methods: {
    mouseup() {
      this.$emit("change:progress", this.progress);
    }
  }
};
</script>
