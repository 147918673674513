<template>
  <Menu v-bind="$props">
    <template v-slot:activator="{ click }">
      <Activator v-bind="$props" @click="click" @clear="clear" />
    </template>

    <template v-slot:menu-content="{ close }">
      <Content v-bind="$props" @change="date => changeDueDate(date, close)" />
    </template>
  </Menu>
</template>

<script>
import Menu from "@/flow/views/components/commons/menu";
import Activator from "./activator";
import Content from "./content";

export default {
  components: { Menu, Activator, Content },
  emits: ["change:dueDate", "delete:dueDate"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeDueDate(dueDate, close) {
      close();
      this.$emit("change:dueDate", dueDate);
    },
    clear() {
      this.$emit("delete:dueDate");
    }
  }
};
</script>
